import React from 'react'

import Layout from '../components/layout'

const Videos = () => (
  <Layout>
    <section className="video-container">
      <h2 className="video__title">Origins</h2>
      <div className="video-player__container">
        <iframe title="Galactic Rex Origins" width="560" height="315" src="https://www.youtube.com/embed/Tdhim9-xvaU?rel=0" frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>        
      </div>
    </section>
  </Layout>
)

export default Videos
